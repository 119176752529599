.customTabColor .nav-link {
  border: 0;
  padding:1rem 1rem;

  &.active{
    color: $white;
    background-color: $yellow;
  }
  &:not(.active){
    color: $white;
    background-color: $gray-600;
  }
}