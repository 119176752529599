.customModalHeaderStyle {
  background-color: $gray-900;

  h1,h2,h3,h4,h5,h6 {
      color: $light
  }
}

.customSectionHeaderStyle {
  background-color: $gray-900;
}

.centerModalContents {
  display: flex; /* establish flex container */
  flex-direction: row; /* make main axis horizontal (default value) */
  justify-content: center; /* center items horizontally, in this case */
  align-items: center; /* center items vertically, in this case */
  height: 300px; /* for demo purposes */
  border: 1px solid black; /* for demo purposes */
  background-color: #eee; /* for demo purposes */
}

.customBadgeError {
  background-color: $yellow;
  padding: 5px;
  color: $white;
  border-radius: 5px;
}